/***        Header          ***/
/*menu utyilisateur*/

body{
    background-color: #f7a723 !important;
    font-family: 'Permanent Marker', cursive;
    color: #fff;
}
.ui.button.form-submit{
    background-color: #ef6b23 !important;
    border-radius: 50% !important;
    padding: 16px 40px !important;
    border:5px solid #fff !important;
    font-family: 'Permanent Marker', cursive;
    color: #fff;
}
.ui.button.form-submit:hover{
    opacity: 0.8 !important;
}
body>div.pusher{
    background-image: url("../images/front/BgdPontNeuf.png") !important;
    background-color: transparent !important;
    background-repeat: no-repeat !important;
    background-position: 0 20rem!important;
    height: auto;
    background-size: 100% !important;
    max-width: 1920px;
    margin: 0 auto;
}
header{
    position:relative !important;
}

.content-fluid.page-content{
    /*background-image: url("../images/front/GradOrange.png") !important;*/
    /*background-repeat:no-repeat !important;*/
    /*background-position: bottom left !important;*/
    min-height:100vh !important;
    padding-bottom:200px;
    margin-bottom:-120px;
}
#footer-top{
    background-image: url("../images/front/DuckLineFooter.png") !important;
    background-color: transparent !important;
    background-repeat:repeat no-repeat !important;
    min-height:112px;
}
#menu , #menu .menu{
    background: #00abe3;
}
.logoDR{
    display: block;
    margin: auto;
}

/***        Banners         ***/
#banner{
    margin-top: -13px;
}

#banner.accueilDuck{
    padding-top: 171px;
    padding-bottom: 171px;
}
#banner.inscription{
    padding-top: 190px;
    padding-bottom: 190px;
}

.ui.button.primary{
    background-color: #00abe3!important;
}

/***        Sponsor         ***/
.carousel img{
    width: 50%!important;
}
.carousel{
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
    border-top: solid 1px #00abe3;
    padding-top: 30px;
}
 h1.partenaires{
     text-align: center;
     border-bottom: solid 1px #00abe3;
     padding-bottom: 50px;
     margin-top: 6%;

 }

/***        Menu principal         ***/
#menuPrincipal a.item:hover,#menuPrincipal .accueil:hover, #menuPrincipal a.item:focus,#menuPrincipal .accueil:focus  {
    background: #00abe3;
}

#menuPrincipal{
    margin-top: 1%;
    margin-bottom: 13px;
}

#menuPrincipal .accueil{
    border-left: none;
}

/***        Accueil         ***/
/*slide*/
.accueilDuck{
    background-image: url("../images/PontNeuf.jpg");
    background-position: 0% 69%;
    background-size: cover;
}

.accueilDuck .Adopter{
background-color: rgba(195,194,194,0.5);
    margin: 0 auto;
    padding: 30px;
    text-align: center;
}

.Adopter a{
    color: white;
}

.accueilDuck strong{
    font-size: 2.5em;
}

.accueilDuck p {
    font-size: 2em;
}

.ui.grid.informationAccueil{
    margin-top: 30px;
}

.informationAccueil img{
    width: inherit;
    display: block;
    margin: auto;
}

/*texte page accueil*/
.informationAccueil .texte{
    margin-top: 5%;
}
.informationAccueil .inherite{
    width: inherit;
    display: block;
    margin: auto;
}
/*compteur cannard*/
.compteur h2{
    background: #00abe3;
    border:solid #dddddd 1px;
    padding: 1%;
    text-align: center;
    color: white;
}
.compteur, .resultats{
    border-radius: 2%;
    border:solid #dddddd 1px;
    display: block;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
}

.compteur #padding{
    padding: 5%;
}

.compteur p{
    font-size: 2em;
}
.compteur img{
    width: 25%;
    float: right;
}

.compteur .ui.teal.progress{
    margin-top: 17%;
    margin-bottom: 16%;
}

.compteur .ui.teal.progress .label{
    margin-top: 6%;
}

/*resultats course*/
.resultats h2{
    background-color: #00abe3;
    padding: 1%;
    text-align: center;
    color: white;
}

.resultats p {
    padding: 1%;
    text-align: center;
}
.resultats .ui.table tbody td {
    text-align: center;
}
.resultats .ui.table {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
    border-collapse: collapse;
}

/***    Association- inscription    ***/
.inscription p{
    font-size: 2.5em;
    font-weight: 700;
    letter-spacing: 0;
    color: darkslategrey;
    line-height: 1em;
    font-family: "nouvelle-vague", sans-serif;
    text-align: center;
}
.inscription .boost{
    background-color: rgba(255,255,255,0.7);
    margin: 0 auto;
    padding: 30px;
    text-align: center;
}
.inscription{
    background-image:  url("../images/Espace1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y:center ;
}
.inscription.ui.grid{
    margin-top: 0;
}

.info.ui.stackable.three.column.grid{
    margin-top: 10px;
}
div.cercle{
    border-radius: 50%;
    border: solid 2px #00abe3;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    margin-left: 18%;
    position: absolute;
    width: 55%;
    height: 80px;
    background: white;
    top: 31px;
}
div.cercle p {
    line-height: 30px;
    padding-top: 21px;
}
.cercle img{
    width: 13%;
}
div.details.un{
    padding-bottom: 11% ;
}
div.details{
    border: 2px solid #00abe3;
    margin-top: 15%;
    text-align: center;
    position: static;
    padding: 20% 5% 15% 5%;
}

div.details p {
    border-radius: 30px;
    padding: 5%;
}
.details p span{
    color: #00abe3;
    font-weight: 700;
}
.details p strong{
    font-weight: bolder;
    color: #00abe3;
    text-decoration: underline;
}
.details img{
    margin-top: 1%;
}

.details a{
    color: black;
}

/*formulaire*/
.formulaire .ui.padded.segment{
    border-top: none;
}
.formulaire{
    border-radius: 5px 5px 5px 5px;
    border: solid 1px #dddddd;
    margin-top: 10%;
}

.formulaire .form_content{
    padding: 15px;
}
.formulaire p:first-child{
    padding: 15px;
    border: solid 1px #dddddd;
    background: #00abe3;
    border-radius: 5px 5px 0 0;
    font-weight: 600;
    font-family: 'Open Sans',Arial, Tahoma, sans-serif;
    font-size: 1.6em;
    color: white;
}

.htag p{
    font-size: 1.5em!important;
    text-align: center;
}
.htag p span{
    color: #00abe3;
}
.htag{
    margin-top: 5%;
}
/***        Les associations        ***/
.cards.liseAsso{
    margin-top: 1%;
}

.liseAsso img.miniDuck{
    width: 15%;
}

.pinned img.miniDuck{
    width: 5%;
}

.pinnedImg{
    /*width: 36% !important;*/
    margin: auto;
}

.pinned.card{
    width: 79%!important;
}

.liseAsso a{
    color: white;
}

.liseAsso span.count {
    color: #c41010;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    padding-right: 30px;
    margin-right: 10px;
    background: url('../images/canard-mini.png') right center no-repeat;
}

/***        Produit     ***/

.acheter .duckbtn{
    padding: 0;
    width: 1%;
}
.acheter button img{
    width: 80%;
}
.acheter button:hover{
    background: #2185D0!important;
}
.acheter{
    margin-top: 2%!important;
}

#shop{
    margin-top: 5%;
}
/***        Pannier     ***/
#summary table.ui.very.basic.table tbody tr:last-child{
    font-size: 1.5em !important;
}
/***        Contact     ***/
#contact{
    margin-bottom: 5%;
}

/***        Footer          ***/
#footer{
    background: #00abe3;
    padding: 10px 20px 5px 20px;
}

#footer, #footer a, #footer h4{
    color: white;
}

#footer .liens{
    margin: auto;
}

#footer .two.wide.column.right.floated{
    margin-top: auto;
    margin-bottom: auto;
}



@media (max-width: 1560px) and (min-width: 1260px) {
    #banner.accueilDuck, #banner.inscription{
        padding-top: 120px;
        padding-bottom: 120px;
    }
}
@media (max-width: 1260px) and (min-width: 1200px) {
    #banner.accueilDuck, #banner.inscription{
        padding-top: 90px;
        padding-bottom: 90px;
    }
}
@media (max-width: 1199px) and (min-width: 990px) {
    div.cercle{
        top: 20px;
    }
}
@media (max-width: 1199px) and (min-width: 767px) {
    #banner.accueilDuck, #banner.inscription{
        padding-top: 80px;
        padding-bottom: 80px;
    }
}
@media (max-width: 990px) {
    #banner.accueilDuck, #banner.inscription{
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
@media (max-width: 990px) and (min-width: 767px) {
    div.cercle{
        top: 12px;
    }
    div.cercle p{
        padding-top: 7px;
    }
    .info .details{
        padding: 25% 5% 15% 5%;
    }
}
@media (max-width: 768px) and (min-width:450px ){
    .cercle img{
        width: 10%;
    }

    .details img{
        margin-top: 10%;
    }
}
@media (max-width: 767px) and (min-width:668px ){
    div.cercle{
        top: 85px;
    }
    div.details{
        padding: 10% 5% 10% 5%;
    }
}
@media (max-width: 767px) {
    div.cercle{
        margin-left: 22%;
    }
}
@media (max-width: 668px) and (min-width:590px ){
    div.cercle{
        top: 70px;
    }
    div.details{
        padding: 10% 5% 10% 5%;
    }
}
@media (max-width: 590px) and (min-width:430px ){
    div.cercle{
        top: 50px;
    }
}
@media (max-width: 550px) {
    header .centered.aligned.column {
    position: absolute!important;
    right: 97px;
    }
    header .right.aligned.column{
    position: absolute!important;
    top: 5px;
    }
    header .left.aligned.column{
    padding-top: 11%;
    padding-bottom: 21%;
    }
    header #logo-mairie{
        margin-top: 111%;}
}
@media (max-width: 449px){
    div.cercle{
        top: 33px;
    }
}
@media (max-width: 394px){
    header #logo-mairie{
        margin-top: 88%;}
}
@media (max-width: 322px){
    div.cercle p{
        padding-top: 4px;
    }
}

img#logo-mairie {
    float: right;
}

/*header .left.aligned.column {*/
    /*padding-top: 11%;*/
